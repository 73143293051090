@import url("//hello.myfonts.net/count/30f520");
@import url("https://use.typekit.net/dny2phk.css");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "acumin-pro", Helvetica, sans-serif;
  letter-spacing: -.06px;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  background-color: #fff;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

p {
  margin: 0;
}


.App a {
  transition: color 1s;
}

.App a:hover {
  color: #F0498A;
}

.fill-container img {
  height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
  object-position: left;
}

.fill-container .fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  transition: width 1s;
  object-fit: cover;
  object-position: left;
}

.fill-container:hover .fill {
  width: 100%;
}

.loading {
  background-color:#ECE7DB;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
}

.loading .fill-container {
  position: absolute;
  top: 0;
  left: 32px;
  height: 80px;
  width: 80px;
}

.loading__img {
  height: 100vh;
  width: auto;
  padding: 80px
}

.landing__img {
  flex: 0 0 50%;
  margin-right: 64px;
  display: flex;
}


@media only screen and (max-width: 768px) {
  .loading__img {
    height: 100vh;
    width: auto;
    padding: 24px
  }

  .loading .fill-container {
    left: 12px;
  }

  .landing {
    display: block;
    height: 100%;
  }

  .landing__img {
    width: auto;
    height: 50vh;
    margin-right: 0;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .landing__img>#shoe {
    max-height: 100%;
    width: auto;
    display: inline-block;
    padding: 0;
  }

  .landing__text {
    margin-top: 32px;
    font-size: 18px;
    line-height: 28px;
    width : 100%
  }

  .landing a {
    bottom: 16px;
    right: initial;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    font-size: 20px;
  }
}

.menu {
  background-color:#CFCFCF;
  height: 100%;
  width: 100%;  
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
}

.menu__map,.menu__hover {
  position: absolute;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.menu__hover.svg-animate {
  object-fit: initial;
  width: 50vh;
  height: 50vh;
  top: 50%;
  left: 120px;
  transform: translateY(-50%);
}

.menu__hover-enter {
  opacity: 0;
}
.menu__hover-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.menu__hover-exit {
  opacity: 1;
}
.menu__hover-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}

.menu__links {
  position: relative;
  z-index: 2;
  width: 33.3333%;
  min-height: 100vh;
  float:right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu__links ul {
  list-style: none;
}

.menu__link {
  margin-top: 48px;
}

.menu__link:first-child{
  margin-top: 0;
}

.menu__link>a {
  display: flex;
  text-decoration: none !important;
  color: black;
}

.menu__link.disabled {
  pointer-events: none;
}

.menu__link.disabled>a,.menu__link.disabled img {
  opacity: .6
}

.menu__images {
  height: 48px;
  width: 48px;
  position: relative;
}

.menu__link:hover .fill {
  width: 100%
}

.menu__link div {
  display: flex;
  flex-direction: column;
  font-family: 'acumin-pro';
  font-size: 32px;
  line-height: 32px;
}
.menu__link h5 {
  font-weight: normal;
  font-family: 'acumin-pro-wide';
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .menu__map,.menu__links {
    width: 100%;
  }

  .menu__links {
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 170px;
    padding-bottom: 24px;
    height: 100vh;
    overflow: scroll;
    justify-content: flex-start;
  }
  
  .menu__link {
    margin-top: 32px;
  }

  .menu__link div {
    font-size: 20px;	
    font-weight: 500;	
    letter-spacing: -0.6px;	
    line-height: 20px;
  }

  .menu__link h5 {
    font-size: 10px;	
    letter-spacing: 0.4px;	
    line-height: 10px;
  }

  .menu__images {
    width: 34px;
    height: 34px;
  }

  .App a:hover {
    color: initial;
  }

  .fill-container .fill {
    width: 100%;
  }

  .fill-container img:not(.fill) {
    display: none;
  }
}

.editorial__header {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  left: 0;
  top: 0px;
  text-align: center;
  font-family: "acumin-pro-wide";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1.07px;	
  line-height: 38px;
  z-index: 9;
}

.editorial__header>* {
  height: 100%;
}

.editorial__header-logo,.editorial__header-menu {
  width: 16.66667%;
}

.editorial__header-logo>* {
  display: block;
  width: 80px;
  height: 80px;
  position: relative;
}

.editorial__header-logo:hover {
  cursor: pointer;
}

.editorial__header-menu {
  text-align: right;
}

.editorial__header-menu a{
  font-family: "acumin-pro";
  text-align: right;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
  line-height: 100%;
  display: block;
  text-decoration: none;
  color: black;
}

.editorial__header-title {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1.07px;
  line-height: 38px;
  width: 83.3333%;
  padding-left: 32px;
  padding-right: 32px;
  color: black;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.editorial {
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: auto;
}

.title{
  display: none;
}

.editorial img,.editorial svg {
  max-height: 100%;
  max-width: 100%;
  align-self: flex-start;
  object-fit: cover;
}

.editorial>*{
  scroll-snap-align: start;
  overflow-y: auto;
}

.editorial__twos,.editorial__quote,.editorial__portrait-image,.editorial__full,.editorial__twos-image {
  display: flex;
  justify-content: center;
  align-items: top;
  height: 100vh;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 120px;
  padding-bottom: 32px;
}

.editorial__quote {
  align-items: center;
}

.editorial__twos img, .editorial__twos p,.editorial__twos .about-svg {
  width: calc(50% - 16px);
  overflow-y: scroll;
}

.editorial__twos.about .about-svg,.editorial__twos.about p {
  overflow-y: initial;
}

.editorial__twos.joe {
  font-size: 22px;
  line-height: 26px;
}

.editorial__twos.joe-portrait p {
  width: calc(66.666667% - 16px);
}

.editorial__twos.joe-portrait img {
  width: calc(33.333333% - 16px);
}

.about .editorial__twos {
  align-items: center;
}

.editorial__text-image>*:first-child,.editorial__twos>*:first-child,.editorial__twos-image>*:first-child {
  margin-right: 32px;
}

.editorial__quote p {
  width: 83.3333%;
  font-family:"acumin-pro-wide";
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -1.2px;	
  line-height: 64px;
}

.editorial__portrait-image img {
  width: calc(33.3333% - 16px);
  margin-right: 32px;
}

.editorial__portrait-image>img:last-child {
  margin-right: 0;
}

.editorial__full img {
  height: 100%;
  width: auto;
}

.editorial__twos-image img {
  width: calc(50% - 16px);
}

.swipe {
  display: none;
}

@media only screen and (max-width: 768px) {
  .editorial {
    scroll-snap-type: none;
    height: auto;
    width: 100vw;
  }
  
  .editorial__header {
    padding-left: 12px;
    padding-right: 12px;
    top: 0;
    height: 80px;
    justify-content: space-between;
  }

  .editorial__header-logo {
    padding-right: 0;
    width: 80px;
  }

  .editorial__header-menu {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .editorial__header-menu a {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.6px;
    line-height: 24px;
  }

  .editorial__header-title {
    font-size: 0;
    display: none;
  }

  .title {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    width: 100%;
    text-align: center;
  }

  .menu .title {
    position: absolute;
  }
  
  .editorial__twos, .editorial__quote, .editorial__portrait-image, .editorial__full, .editorial__twos-image {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 48px;
  }

  .editorial>*:not(.title) {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.5px;
    width: 100%;
    height: auto;
  }

  .editorial__twos{
    flex-direction: column;
  }

  .editorial__twos.about {
    flex-direction: column;
    height: 100%;
  }

  .editorial__twos.joe-portrait p {
    width: 100%;
  }
  
  .editorial__twos.joe-portrait img {
    width: 100%;
  }


  .editorial__twos>*:last-child {
    margin-right: 0;
    margin-top: 40px;
  }

  .editorial__twos.about>*:last-child {
    margin-top: 40px;
  }

  .editorial__twos img,.editorial__twos p,.editorial__twos .about-svg {
    width: 100%;
    margin: 0;
  }


  .editorial__quote p{
    width: 100%;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -0.8px;
    line-height: 40px;
  }

  .editorial__full {
    display: block;
  }

  .editorial__full img {
    width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
  }

  .editorial__twos-image, .editorial__portrait-image {
    width: 100vw;
    height: 100%;
    overflow-x: auto;
    justify-content: space-between;
    flex-flow: row nowrap;
    scroll-snap-type: x mandatory;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }

  .editorial__twos-image::-webkit-scrollbar,.editorial__portrait-image::-webkit-scrollbar { 
    display: none; 
  }
  
  .editorial__twos-image img, .editorial__portrait-image img{
    width: calc(100vw - 24px);
    height: 100%;
    margin-right: 12px;
    margin-left: 12px;
    scroll-snap-align: center;
    scroll-margin-left: 12px;
    scroll-margin-right: 12px;
    animation-name: none !important;
    position: relative;
  }
  
  .editorial__twos-image img:first-child, .editorial__portrait-image img:first-child {
    margin-left: 0px;
  }

  .editorial__twos-image img:last-child, .editorial__portrait-image img:last-child {
    margin-right: 0px;
  }

  .editorial__twos-image::after,.editorial__portrait-image::after {
    content: '';
    flex: 0 0 12px;
    height: 1px;
  }

  .swipe {
    display: block;
    position: absolute;
    font-size: 16px;
    right: 12px;
    bottom: 6px;
  }

}

footer {
  position: sticky;
  border-top: 2px solid #000000;
  padding-top: 48px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 48px;
  display: flex;

}

.footer__desc {
  width: calc(50%);

  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.6px;
  line-height: 32px;
}

.footer__desc a {
  color: #000;
}

.footer__spacer {
  width: calc(16.66667%);
  margin-left: 32px;
}

.footer__social {
  width: calc(16.66667%);
  margin-left: 32px;
  flex-direction: column;
}

.footer__social a {
  display: block;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
  line-height: 32px;
  text-decoration: none;
  color: #000000;
  text-align: left;
}

.footer__social a:hover {
  text-decoration: underline;
}

.footer__copyright {
  width: calc(16.66667%);
  text-align: right;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
  line-height: 32px;
  text-decoration: none;
}

footer .illustrations {
  margin-top: 12px;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px
}



@media only screen and (max-width: 768px) { 
  footer {
    flex-direction: column;
    padding-top: 24px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .footer__desc {
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 28px;
  }

  .footer__social,.footer__copyright {
    margin-left: 0;
    margin-top: 12px;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 40px;
    text-align: left;
  }
}